export const COLUMNS = [
    {
        Header: 'Id',
        accessor: 'id'
    },
    {
        Header: 'First Name',
        accessor: 'firstName'
    },
    {
        Header: 'Last Name',
        accessor: 'lastName'
    },
    {
        Header: 'Gender',
        accessor: 'gender'
    },
    {
        Header: 'Age',
        accessor: 'age'
    },
    {
        Header: 'State Of Origin',
        accessor: 'originState'
    },
    {
        Header: 'LGA Of Origin',
        accessor: 'originLga'
    },
    {
        Header: 'Educational Qualification',
        accessor: 'educationalQulatification'
    },
    {
        Header: 'Employment Status',
        accessor: 'employmentStatus'
    },
    {
        Header: 'Date Last Seen',
        accessor: 'lastSeenDate'
    },
    {
        Header: 'Last Date Of Info',
        accessor: 'lastDateHearedNews'
    },
    {
        Header: 'Where Was Seen Last',
        accessor: 'whereLastSeen'
    },
    {
        Header: 'Reason For Disappearance',
        accessor: 'reasonForDisappearance'
    }
]