//import logo from './logo.svg';
import './App.css';
import { MissingPersonsTable } from './components/MissingPersonsTable';
import { PersonDetails } from './components/PersonDetails';
import { About } from './components/About';
import CssBaseline from '@material-ui/core/CssBaseline'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { NoMatch } from './components/NoMatch';
import Footer from './components/Footer';
import { MissingMap } from './components/MissingMap';

function App() {
  return (
    <>
    <CssBaseline />
    <Navbar />
    <Routes>
      <Route path='/' element={<MissingPersonsTable />} />
      <Route path='/about' element={<About />} />
      <Route path='/map' element={<MissingMap />} />
      <Route path='details/:index' element={<PersonDetails />} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
