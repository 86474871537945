import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export class MissingMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };
    
    render () {
        return (
            <div>
                {this.state.loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', height: '84vh', flexDirection: 'column', verticalAlign: 'middle', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>  
                ) : null }
                <div className="container rsvp-wrapper map-container">
                    <iframe onLoad={this.hideSpinner} title="Borno Map" className="state-map" src="https://africageoportal.maps.arcgis.com/apps/Embed/index.html?webmap=8b43b5bf9be146d78da2ce52fb590cdf&extent=9.8679,10.1394,16.3443,13.6008&zoom=true&previewImage=false&scale=false&disable_scroll=true&theme=light" />
                </div>
            </div>
        )
    }
}