import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React from "react";
import { Link } from 'react-router-dom';

export const NoMatch = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '80vh' }}>
            <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
                <Typography variant="h4" gutterBottom>Page Not Found</Typography>
                <Typography variant="body2" sx={{ pb: 2 }}>Click on the link below to go back to the dashboard.</Typography>
                <Link to='/' style={{ textDecoration: "none" }}><Button variant="contained">Back to Dashboard</Button></Link>
            </Container>
        </Box>
    )
}