import * as React from 'react';
import { NavLink } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

export const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} alt="Logo" src="logo.svg">MPP</Avatar>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <nav>
                <MenuItem key='dashboard' onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <NavLink to='/'>Dashboard</NavLink>
                  </Typography>
                </MenuItem>
                <MenuItem key='geo' onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <NavLink to='/map'>Geographical Distribution</NavLink>
                  </Typography>
                </MenuItem>
                <MenuItem key='about' onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <NavLink to='/about'>About</NavLink>
                  </Typography>
                </MenuItem>
              </nav>
            </Menu>
          </Box>
          <Avatar sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} alt="Logo" src="logo.svg">MPP</Avatar>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Missing Persons
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <nav>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, mr: 2, display:"inline" }}>
                  <NavLink className='mp-link' to='/'>Dashboard</NavLink>
              </Typography>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, mr: 2, display:"inline" }}>
                  <NavLink to='/map'>Geographical Distribution</NavLink>
              </Typography>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, mr: 2, display:"inline" }}>
                  <NavLink to='/about'>About</NavLink>
              </Typography>
            </nav>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <a className='logo-link' href='https://humanglemedia.com' target='_blank' rel='noreferrer'><img className='hum-logo' src='humangle-white.png' alt='HumAngle Logo' /></a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}