import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Image from 'mui-image';
import Stack from "@mui/material/Stack";

export const About = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '80vh' }}>
            <Container component="main" sx={{ mt: 5, mb: 2 }} maxWidth="md">
                <Typography variant="h4" gutterBottom>About The OSIWA Missing Person's Project</Typography>
                <Typography variant="body2" sx={{ pb: 2, textAlign: 'justify' }}>The Missing Persons Register’s Population and Amplification Project, supported by the Open Society Initiative for West Africa (OSIWA), is aimed at supporting the establishment of the national missing persons register by providing credible information from sources across Northeast Nigeria and giving life to the statistics by telling human-angle stories on the development.</Typography>
                <Typography variant="body2" sx={{ pb: 2, textAlign: 'justify' }}>The project is designed to bring more attention to practices such as forced disappearances and abductions that lead to people going missing and the consequences of these practices at the level of individuals, loved ones, and families of victims.</Typography>
                <Typography variant="body2" sx={{ pb: 2, textAlign: 'justify' }}>The online tracker contains information gathered by field enumerators across different Local Government Areas in Borno State. The data gathered is tailored in such a way that could allow for them to be tracked easily by the authorities or other persons with relevant information. The database will be updated with new information as time passes.</Typography>
                <Typography variant="body2" sx={{ pb: 2, textAlign: 'justify' }}>It is hoped that the information published here will also aid research and advocacy by other organisations on the subject.</Typography>
                <Typography variant="body2" sx={{ pb: 2, textAlign: 'justify' }}>Our objectives include the proper, verifiable record-keeping of people declared to have gone missing; helping families to achieve closure with the confirmation of the victims’ current circumstances; advocating for the proper documentation of people detained in connection with the Boko Haram insurgency; and triggering institutional reforms in the administration of criminal justice especially within the context of counter-insurgency.</Typography>
                <Stack direction="row" spacing={2}>
                <Image src="OSIWA-blue.png"
                    height="100%"
                    width="100%"
                    fit="cover"
                    duration={3000}
                    easing="cubic-bezier(0.7, 0, 0.6, 1)"
                    showLoading={ false }
                    errorIcon={ true }
                    shift={null}
                    distance="100px"
                    shiftDuration={900}
                    bgColor="inherit"
                />
                <Image src="humangle-logo.png"
                    height="100%"
                    width="100%"
                    fit="cover"
                    duration={3000}
                    easing="cubic-bezier(0.7, 0, 0.6, 1)"
                    showLoading={ false }
                    errorIcon={ true }
                    shift={null}
                    distance="100px"
                    shiftDuration={900}
                    bgColor="inherit"
                />
                </Stack>
            </Container>
        </Box>
    )
}