import React from "react"
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import OSIWA_MPP from './osiwaMpp.json'

import { useParams } from "react-router-dom"

import Checkbox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import Sticky from 'react-sticky-el';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';

export const PersonDetails = () => {
    const params = useParams()
    const index = params.index

    return (
        <Box sx={{ flexGrow: 1, m:2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Sticky disabled={window.innerWidth < 600}>
                    <Paper>
                        <Card >
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                height="250"
                                /*image={OSIWA_MPP[index].photo === null && OSIWA_MPP[index].gender === 'Male' ? "https://www.humangleservices.com/assets/img/male.png" : OSIWA_MPP[index].photo === null && OSIWA_MPP[index].gender === 'Female' ? "https://www.humangleservices.com/assets/img/female.png" : `https://www.humangleservices.com/assets/img/${OSIWA_MPP[index].photo}`}*/
                                image={OSIWA_MPP[index].gender === 'Male' ? "https://www.humangleservices.com/assets/img/male.png" : "https://www.humangleservices.com/assets/img/female.png"}
                                alt={OSIWA_MPP[index].firstName}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {OSIWA_MPP[index].firstName} {OSIWA_MPP[index].lastName} {OSIWA_MPP[index].otherName}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Paper>
                    <Paper sx={{ mt: 2, display: { xs: 'none', sm: 'block' } }}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="#mpi">
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText sx={{ ml: -3 }} primary="Missing Person's Info" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="#con">
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText sx={{ ml: -3 }} primary="Condition" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="#rt">
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText sx={{ ml: -3 }} primary="Reporters Testimony" />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding>
                                <ListItemButton component="a" href="/">
                                <ListItemIcon>
                                    <ArrowBackIosIcon />
                                </ListItemIcon>
                                <ListItemText sx={{ ml: -3 }} primary="Back to Dashboard" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Paper>
                    </Sticky>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Paper id="mpi" sx={{ mb: 2 }} variant="outlined">
                        <Stack sx={{ px: 2 }}>
                            <Typography sx={{ py: 2, fontWeight: 'bold' }} variant="h6">Missing Person's Info</Typography>
                            <Stack sx={{ pb:2 }}>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Gender: <span className="person_att">{OSIWA_MPP[index].gender}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Age: <span className="person_att">{OSIWA_MPP[index].age}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Religion: <span className="person_att">{OSIWA_MPP[index].religion}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>State Of Origin: <span className="person_att">{OSIWA_MPP[index].originState}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Local Government Area(LGA): <span className="person_att">{OSIWA_MPP[index].originLga}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Educational Qualification: <span className="person_att">{OSIWA_MPP[index].educationalQulatification}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Employment Status: <span className="person_att">{OSIWA_MPP[index].employmentStatus}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Occupation: <span className="person_att">{OSIWA_MPP[index].occupation}</span></Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper id="con" sx={{ mb: 2 }} variant="outlined">
                        <Stack sx={{ px: 2 }}>
                            <Typography sx={{ py: 2, fontWeight: 'bold'  }} variant="h6">Condition</Typography>
                            <Stack sx={{ pb:2 }}>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Last seen date: <span className="person_att">{OSIWA_MPP[index].lastSeenDate}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>How the person went missing: <span className="person_att">{OSIWA_MPP[index].howMissing}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Has the person been found: <span className="person_att">{OSIWA_MPP[index].isHeFound}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>When the person was found: <span className="person_att">{OSIWA_MPP[index].whenFound}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Where the person was found: <span className="person_att">{OSIWA_MPP[index].whereFound}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Condition of the person when found: <span className="person_att">{OSIWA_MPP[index].conditionWhenFound}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1, mt: 1 }}>Health Condition</Typography>
                                    <Stack spacing={1} sx ={{ mb: 3, ml: 4}}>
                                        <Stack direction="row">Is Dead {OSIWA_MPP[index].isDead === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Well and Healthy {OSIWA_MPP[index].isWellAndHealthy === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Sick {OSIWA_MPP[index].isSick === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Pregnant {OSIWA_MPP[index].isPregnant === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">With Children {OSIWA_MPP[index].withChildren === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Disabled {OSIWA_MPP[index].isDisabled === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                    </Stack>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>More details on health condition: <span className="person_att">{OSIWA_MPP[index].healthConditionDetails}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Present location of person: <span className="person_att">{OSIWA_MPP[index].presentLocation}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Government support: <span className="person_att">{OSIWA_MPP[index].isUnderGovCare}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>If found, how he/she was found: <span className="person_att">{OSIWA_MPP[index].howPersonFound}</span></Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper id="rt" sx={{ mb: 2 }} variant="outlined">
                        <Stack sx={{ px: 2 }}>
                            <Typography sx={{ py: 2, fontWeight: 'bold'  }} variant="h6">Reporter's Testimony</Typography>
                            <Stack sx={{ pb:2 }}>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Relationship to the missing person: <span className="person_att">{OSIWA_MPP[index].relationship}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Any news about the missing person: <span className="person_att">{OSIWA_MPP[index].hearedAnyNews}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>How the news was heared: <span className="person_att">{OSIWA_MPP[index].howYouHeared}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>What was heared: <span className="person_att">{OSIWA_MPP[index].whatYouHeared}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>The missing person's health condition:</Typography>
                                    <Stack spacing={1} sx ={{ mb: 3, ml: 4}}>
                                        <Stack direction="row">Is Alive {OSIWA_MPP[index].isAlive === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Sick {OSIWA_MPP[index].isSickByRep === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Recruited {OSIWA_MPP[index].isRecruited === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Married {OSIWA_MPP[index].isMarried === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is Pregnant {OSIWA_MPP[index].isPregnantByRep === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">With Children {OSIWA_MPP[index].withChildrenByRep === 1 ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                    </Stack>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Last date of hearing about missing person: <span className="person_att">{OSIWA_MPP[index].lastDateHearedNews}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Where the person was last seen: <span className="person_att">{OSIWA_MPP[index].whereLastSeen}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>The reason for the person's disappearance: <span className="person_att">{OSIWA_MPP[index].reasonForDisappearance}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Some info about the person:</Typography>
                                    <Stack spacing={1} sx ={{ mb: 3, ml: 4}}>
                                        <Stack direction="row">With Disabilities {OSIWA_MPP[index].withDisabilities === 'Yes' ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">With Disfiguration {OSIWA_MPP[index].withDisfiguration === 'Yes' ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">With Permanent Injuries {OSIWA_MPP[index].withPermanentInjuries === 'Yes' ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">With Facial Mark {OSIWA_MPP[index].withFacialMarks === 'Yes' ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is He Fair {OSIWA_MPP[index].isFair === 'Yes' ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                        <Stack direction="row">Is He Dark {OSIWA_MPP[index].isDark === 'Yes' ? <Checkbox /> : <CheckBoxOutlineBlankIcon />}</Stack>
                                    </Stack>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Other description about the person: <span className="person_att">{OSIWA_MPP[index].otherDescription}</span></Typography>
                                <Typography variant="subtitle2" sx={{ pb:1 }}>Other info shared: <span className="person_att">{OSIWA_MPP[index].anyMoreInfoToShare}</span></Typography>
                            </Stack>
                        </Stack>
                    </Paper>              
                </Grid>
            </Grid>
        </Box>
    )
}