import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function Copyright() {
    return (
      <Typography variant="caption" color="text.secondary">
        {'Copyright © '}
        <Link color="inherit" href="https://humanglemedia.com">
          HumAngle
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  export default function Footer() {
    return (
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="caption" sx={{ mb:1, textAlign: "center" }}>
              This dashboard was created by HumAngle in collaboration with the Open Society Initiative for West Africa (OSIWA) under the Missing Persons Register’s Population and Amplification Project.
            </Typography>
            <Typography />
            <Copyright />
          </Container>
        </Box>
    );
  }