import React, { useMemo } from 'react'
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table'
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import OSIWA_MPP from './osiwaMpp.json'
import { COLUMNS } from './columns'
import './table.css'

import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { useNavigate } from 'react-router-dom'

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const MissingPersonsTable = () => {

    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => OSIWA_MPP, [])

    const tableInstance = useTable ({
        columns,
        data,
        initialState: {
            hiddenColumns: ["id", "gender", "originState", "lastDateHearedNews", "whereLastSeen", "reasonForDisappearance"]
        }
    }, useGlobalFilter, useSortBy, usePagination)

    const {getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canNextPage, gotoPage, pageCount, setPageSize, state, prepareRow, allColumns, rows, setGlobalFilter} = tableInstance

    const { pageIndex, pageSize, globalFilter } = state

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeRowsPerPage = (e) => {
        setPageSize(Number(e.target.value));
    };

    function TablePaginationActions() {
        const theme = useTheme();
      
        const handleFirstPageButtonClick = () => {
            gotoPage(0);
        };
      
        const handleBackButtonClick = () => {
            previousPage();
        };
      
        const handleNextButtonClick = () => {
            nextPage();
        };
      
        const handleLastPageButtonClick = () => {
            gotoPage(pageCount - 1);
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={pageIndex===0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={pageIndex===0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={!canNextPage}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={!canNextPage}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }

    const handleChangePage = () => {};

    function handleRowClick(personId) {
        navigate(`details/${personId}`);
    }

    return (
        <Box sx={{ flexGrow: 1, m:2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Paper sx={{ p: 1 }}>
                  <Stack direction={{xs: 'column-reverse', sm: 'row-reverse'}} alignItems="center" justifyContent="space-between">
                    <Item>
                      <Button
                          id="demo-customized-button"
                          aria-controls={open ? 'demo-customized-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClick}
                          endIcon={<ViewColumnIcon />}
                      >
                          Choose Column
                      </Button>
                      <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                      >
                              {
                                  allColumns.map(column => (
                                      <MenuItem key={column.id} value={column.Header}>
                                          <Checkbox {...column.getToggleHiddenProps()}/>
                                          <ListItemText primary={column.Header} />
                                      </MenuItem>
                                  ))
                              }
                      </StyledMenu>
                    </Item>
                    <Item>
                      <TextField value={globalFilter || ''} label="Type to filter the table" onChange={e => setGlobalFilter(e.target.value)} />
                    </Item>
                    <Item sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                      <Typography variant='h4'>Missing Persons Dashboard</Typography>
                    </Item>
                  </Stack>
                </Paper>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ p: 1 }}>
                        
                        <MaUTable {...getTableProps()}>
                            <TableHead>
                                {
                                    headerGroups.map((headerGroup) => (
                                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map((column) =>  (
                                                    <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        <span>
                                                            {column.isSorted ? (column.isSortedDesc ? <MdOutlineArrowDropDown /> : <MdOutlineArrowDropUp />) : ''}
                                                        </span>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                }
                                
                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {
                                    page.map((row) => {
                                        prepareRow(row)
                                        return (
                                            <TableRow {...row.getRowProps()} onClick={() => handleRowClick(row.id)} style={{ cursor: 'pointer' }}>
                                                {
                                                    row.cells.map((cell) => {
                                                        return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPage={pageSize}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        SelectProps={{
                                            inputProps: {
                                            'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        page={pageIndex}
                                        count={rows.length}
                                    />
                                </TableRow>
                            </TableFooter>
                        </MaUTable>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}